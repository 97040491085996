const messages = {
  hu: {
    parameters: {
      yes: 'ÉS',
    },
    com: {
      phase: {
        label: 'Címke',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'Termékkód',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Gyártó',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Termék szám',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Garancia',
          months: 'hónapok',
        },
      },
      totalAmount: {
        label: 'Összesen',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ további ({number}).',
    },
    orders: {
      actions: {
        all: 'Minden rendelés',
      },
      myOrdersScoringBlog: {
        title: 'rendeléseim',
      },
      ordersList: 'Megrendelések',
      phase: {
        1: 'Elfogadott',
        '01-new': 'Elfogadott',
        10: 'Nyisd ki',
        20: 'Fizetésre vár',
        30: 'Fizetett',
        98: 'Mentett',
        99: 'Törölve',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Csomagszám',
            phase: 'Állapot',
            paid: 'Fizetett',
            total: 'Nettó ár',
            totalWithTax: 'Összesen',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Kosár',
        buyButton: 'RENDBEN',
        emptyBasket: 'Az Ön kosara üres',
        buyThere: 'Korábban vásároltam itt',
        login: 'Belépés',
        notFill: 'A szállítási adatok nincsenek kitöltve',
        fill: 'Töltse ki az információkat',
        change: 'Módosítsa a dátumokat',
        popupButton: 'mutasd meg a térképen',
        payment: {
          title: 'FIZETÉSI MÓD',
          free: 'Ingyenes',
          changePaymentBtn: 'FIZETÉSVÁLTOZÁS',
        },
        shipping: {
          title: 'SZÁLLÍTÁSI MÓD',
          free: 'Ingyenes',
          changeShippingBtn: 'SZÁLLÍTÁSI VÁLTOZÁS',
          popup: {
            title: 'Információ',
            text: 'Kiszállítás az Ön címére futárszolgálattal {service}',
          },
        },
      },
      actions: {
        all: 'Bevásárló kosarak',
      },
      myBasketsScoringBlog: {
        title: 'Mentett bevásárlókocsik',
      },
      cartSummaryParameters: {
        caption: 'Paraméterek',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'Leírás',
      parameters: 'Paraméterek',
      package: 'Csomag tartalma',
      fotoAndVideo: 'Kép és videó',
      alternatives: 'Alternatívák',
      alternativesText: 'Sajnos jelenleg nincs csere az Ön által kiválasztott termékre.',
      questions: 'Kérdések',
      productDescriptionNotAvailable: 'A termék leírása nem elérhető',
    },
    newsletter: {
      phone: '+386 1 82 82 053',
      subscribe: {
        title: 'Ne maradjon le a különleges ajánlatokról',
        inputText: 'az email címed',
        button: 'Küld',
      },
    },
    globalNavigation: {
      home: 'Főoldal',
      shopping: 'megvesz',
      orders: 'Megrendelések',
      shoppingLists: 'Bevásárlólisták',
      articles: 'hírek',
    },
    homePageWhy: {
      0: {
        title: 'Vásároljon biztonságosan',
        content:
          'Engedélyezett 3D Secure fizetési rendszer, amely optimális biztonságot garantál az online vásárlás során.',
      },
      1: {
        title: 'Elégedett vagy visszatért',
        content: 'Joga van meggondolni magát. Használja ki gyors, 14 napon belüli visszatérítési szolgáltatásunkat.',
      },
      2: {
        title: 'Áruk saját raktárban',
        content: 'Az összes áru 98%-a raktáron van és azonnali kiszállításra készen áll.',
      },
      3: {
        title: 'Ingyenes szállítás',
        content: 'INGYENES szállítás bizonyos digitális fényképezőgépekhez és objektívekhez.',
      },
      title: 'MIÉRT MI?',
    },
    startPage: {
      title: 'Címlap',
      gotoShopping: 'megvesz',
    },
    shoppingCart: {
      actions: {
        buy: 'KOSÁRBA',
      },
      buyBtn: 'KOSÁRBA',
      alternatives: 'ALTERNATÍV',
      shipping: {
        changePoint: 'Változtat',
      },
      agreements: {
        termsRequired: 'El kell fogadnia a feltételeket.',
      },
      validation: {
        someProductNotAvailable: 'Egyes termékek nem állnak rendelkezésre elegendő mennyiségben.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Termékkód:',
    },
    productListPage: {
      title: 'Katalógus',
      orders: {
        recommended: 'Ajánljuk',
        name: 'Név',
        priceAsc: 'A legmagasabb ártól',
        priceDesc: 'A legalacsonyabb ártól',
      },
    },
    orderStatuses: {
      storno: 'Törlés',
      synchronized: 'Küldött',
      waiting: 'Várakozás a szinkronizálásra',
    },
    orderPreview: {
      items: 'Termékek',
    },
    cartRegisterForm: {
      title: 'Regisztrációs űrlap',
    },
    cartPayment: {
      title: 'FIZETÉSI MÓD',
    },
    cartPreSummaryPage: {
      title: 'Kosár áttekintése',
      actions: {
        nextStep: 'Egyetértek és folytatom',
      },
    },
    cartCompanyForm: {
      description: 'Töltsd ki, ha egy cégnek vásárol árut',
    },
    cartDeliveryForm: {
      description: 'Töltsd ki, ha a szállítási cím eltér a számlázási címtől',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Mikor kapom meg a megrendelt árut?',
    },
    productDetailStockInfo: {
      available: 'A termék fizikailag a disztribúciós raktárunkban van.',
      external: 'A termék úton van raktárunkba',
      notAvailable: 'Az áru átmenetileg nem elérhető',
      labels: {
        free: 'INGYENES SZÁLLÍTÁS',
        showroom: 'MEGJELENÍTVE A SHOWROOMBAN',
      },
    },
    productGifts: {
      btnName: 'AJÁNDÉK',
      giftValue: 'Értékben',
    },
    homepage: {
      productOfMonth: 'Ne hagyd ki',
      catalogFavorite: 'Legnépszerűbb kategória',
      hotTips: 'Toplista',
      recommended: 'Ajánljuk',
    },
    productQuestion: {
      firstName: 'Keresztnév',
      lastName: 'Vezetéknév',
      email: 'Email',
      subject: 'Termék',
      question: 'Írj egy kérdést',
      gdpr: 'Kérdés elküldésével hozzájárulok személyes adatok válaszszerzés céljából történő kezeléséhez.',
      gdprLink: 'link a GDPR-hoz',
      button: 'Nyújtsa be érdeklődését',
      subjectContent: 'Érdeklődés a következő termékkel kapcsolatban: {product}, kód: {sku}',
      title: 'El kell foganapa a személyes adatok kezelését.',
      success: 'az űrlapot elküldték',
    },
    lastVisitedProducts: 'Meglátogatott termékek',
    productAlternativeProducts: 'Alternatívák',
    productAccessoryProducts: 'kiegészítők',
    productReplacementProducts: 'Alternatívák',
    productAccessories: 'kiegészítők',
    productReplacements: 'Helyettesítések',
    appProductDetailTabs: {
      description: 'Leírás',
      parameters: 'Paraméterek',
      package: 'Csomag tartalma',
      query: 'Lekérdezés',
      media: 'Kép és videó',
      alternatives: 'Alternatív',
      accessories: 'kiegészítők',
      replacements: 'kiegészítők',
      form: {
        enum: '!!!! ENUM ÉRTÉK',
      },
    },
    cartSummaryPage: {
      title: 'Összefoglaló rendelés előtt',
      actions: {
        continueShopping: 'folytatni a vásárlást',
        saveOrder: 'Mentse el a rendelést',
      },
      expectedDeliveryDate: {
        label: 'Kívánt szállítási dátum',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Megjegyzés a fuvarozóknak',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Fizetési átjáró',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'A fiókom',
      content: 'Tartalom',
      contactInfo: {
        newsletter: {
          title: 'Hírlevél',
          content: 'Híreket szeretnék kapni',
        },
        title: 'Elérhetőség',
      },
      orders: {
        title: 'Rendelési előzmények',
      },
      invoices: {
        title: 'Számlák',
      },
      myOrders: 'Megrendelések',
      myInvoices: 'Számlák',
      logout: 'Kijelentkezés',
      login: 'Belépés',
      favorites: {
        title: 'Kedvenc',
      },
      changePassword: {
        title: 'A jelszó megváltoztatása',
        fields: {
          login: 'Bejelentkezés (nem módosítható)',
          originalPassword: 'Eredeti jelszó',
          newPassword: 'Új jelszó',
          confirmNewPassword: 'Jelszó megerősítése',
        },
        button: 'Változtatások mentése',
      },
    },
    orderSavedThankPage: {
      title: 'Köszönjük megrendelését {orderNumber}',
      thankMessage: {
        email: 'info@ceskyvelkoobchod.cz',
        phoneNumber: '',
        template:
          'Kérjük, ellenőrizze e-mail postafiókját a megrendelés megerősítéséhez. Mentse el az e-mailt saját használatra. A megrendelésével kapcsolatos összes információ megtalálható az alábbi részben.',
      },
      order: 'Rendelésszám:',
      payment: 'a nap fizetése:',
      cancelled: 'Törölve',
      accepted: 'Elfogadott',
      pending: 'Várakozás',
      paid: 'Fizetett',
      actions: {
        back: 'Vissza a főoldalra',
        detail: 'Megrendelés áttekintése',
        continue: 'folytatni a vásárlást',
      },
      decline: 'Elutasítva',
    },
    shoppingLists: {
      title: 'Bevásárló lista',
      actions: {
        saveAsShoppingList: 'Bevásárlólista mentése',
        importShoppingListToCart: 'Importálás',
      },
    },
    searchResultPage: {
      search: 'Keresési kifejezés',
      results: 'Keresési eredmények',
    },
    homePageArticles: {
      title: 'hírek',
      button: 'Az összes cikk megtekintése',
    },
    loginPage: {
      title: 'Belépés',
      forgotPassword: 'Jelszó visszaállítása',
      email: 'Emailben',
      sendEmail: 'Küldj emailt',
      info: 'Kapni fog egy e-mailt a jelszó megváltoztatásához szükséges linkkel',
      password: 'Jelszó',
      notSame: 'Jelszó nem egyezik',
      newPassword: 'Új jelszó',
      confirmPassword: 'Jelszó megerősítése',
      changePassword: 'A jelszó megváltoztatása',
    },
    productPrice: {
      withoutTax: 'Nettó',
    },
    productDetailPage: {
      availability: {
        dateRange: 'Rendeljen {today} napon, és {minDays}-{maxDays} napon belül kiszállítjuk.',
        today: 'Rendeld meg ma {time}-ig és még ma postázzuk.',
        todayWord: 'Ma',
        tomorrow: 'Rendeljen {today}, és holnap kiszállítjuk.',
        nextDateTime: '13:30',
        dayOfWeek: {
          message: 'Rendeljen {today}, és mi {day} szállítjuk.',
          1: 'hétfő',
          2: 'kedd',
          3: 'szerda',
          4: 'csütörtök',
          5: 'péntek',
          6: 'szombat',
          0: 'vasárnap',
        },
      },
      allDescription: 'TERMÉKLEÍRÁS',
      demoProduct: {
        text: 'VÁSÁROLJON OLCSÓBBAN',
      },
      productNote: {
        title: '???',
        note: '2. megjegyzés – További állapotleírás vagy hasonló termékinformáció, miért van kedvezmény és mi van a terméken...',
      },
      adornments: {
        notForSale: 'Nem eladó',
        usedProduct: 'Használt',
        freeGift: 'Ajándék ingyen',
        freeShipping: 'Ingyenes szállítás',
        eol: 'Az értékesítés véget ért',
        demo: 'Demo termék',
        sale: 'Kedvezmény',
      },
      disponibility: {
        primaryLessThan: 'Raktáron kevesebb mint {count} darab',
        primaryMoreThan: 'Raktáron {count, plural, zero {one piece} other {{count} vagy több darab}}',
        secondaryLessThan: 'Raktáron az előkészítő raktárban kevesebb mint {count} darab',
        secondaryMoreThan:
          'Raktáron a közelgő raktárban {count, plural, zero {egy darab} other {{number} és több darab}}',
        external: 'A termék úton van raktárunkba',
        unavailable: 'A termék nem elérhető',
        showroom: 'Kiállítva a bemutatóteremben',
        soldout: 'A termék elfogyott',
        'on-way': {
          d: '',
          m: 'Úton: {from, plural, one {minute} two {minute} other {minute}}',
          M: 'Útközben {from, plural, one {hónap} two {hónap}​few {hónap} other {hónap}}',
          dRange: 'Úton {to, plural, few {{from}-{to} napig} oneébch {{from}-{to} napig}}',
          mRange: 'Útközben {from}-{to, plural, one {day} few {day} other {days}}',
          MRange: 'Útközben {from}-{to, plural, one {hónap} two {hónap} ​few {hónap} ​other {hónap}}',
          date: '{datum}',
        },
        time: 'Úton: {count, plural, zero {egy darab} other {{count} és még több darab}}',
      },
    },
    comparePage: {
      title: 'Hasonlítsa össze',
      removeButton: 'Távolítsa el az összehasonlításból',
      nothing: 'Nincsenek összehasonlítható termékek',
    },
    filter: {
      reset: 'Állítsa vissza a szűrőt',
      search: 'Keresés',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Rendelési feltételek',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'KOSÁRBA',
      },
    },
    invoiceBlogPreview: {
      title: 'Saját számláim',
      button: 'Minden fiók',
    },
    userProfilePage: {
      logout: 'Kijelentkezés',
      setting: 'Beállítások',
      passwordChange: 'A jelszó megváltoztatása',
    },
    appBarActionRegion: {
      changeRegion: 'Válasszon nyelvet',
      label: 'rendben',
    },
    subCategories: {
      title: 'Alkategória',
    },
    aboutShoppingPage: {
      title: 'A vásárlás folyamata',
      contact: 'Kapcsolat',
      electronicClosureOfRevenue: 'Értékesítések elektronikus nyilvántartása',
      informationDuty: 'Információk a személyes adatok feldolgozásárol',
      processingOfPersonalData: 'Személyes adatok feldolgozása',
      cashback: 'Cashback',
      delivery: 'Szállítás',
      availability: 'Warenverfügbarkeit',
      methodOfPayment: 'Zahlungsarten',
      termsAndConditions: 'AGB',
      termsOfAppeal: 'Reklamációs feltételek',
      contractwWithdrawal: 'Szerződéstől való elállás',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Adószámát ellenőriztük, az árak áfa nélkül kerülnek felszámításra.',
        viesNotVerified: 'Adószáma nincs igazolva, vagy nem felel meg az áfa nélküli vásárlás feltételeinek.',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'SZEMÉLYES ADATOK',
      pleaseComplete: 'TÖLT',
      closeButton: 'Bezárás',
    },
    cartPromoCodes: {
      title: 'PROMÓCIÓS KÓD ALKALMAZÁSA',
      textFieldPlaceholder: 'PROMÓCIÓS KÓD',
      textFieldHelperText: 'Csak egy promóciós kódot lehet alkalmazni rendelésenként',
      applyPromoCodeButton: 'ALKALMAZNI',
    },
    cookiesBar: {
      agreeBtn: 'rendben',
      title: 'FIGYELMEZTETÉS A WEBOLDALUNKON A SÜTIKRE VONATKOZÓAN',
      text: 'Cégünk saját cookie-kat (ún. alapvető cookie-kat) és harmadik féltől származó cookie-kat használ ezen a weboldalon. Ezen fájlok és érdeklődés alapján a weboldalt az ügyfelek elvárásainak megfelelően alakítjuk, optimalizáljuk ajánlatainkat és megelőzzük a csalás kockázatát. Néhány szükséges cookie nélkül a weboldal nem tud megfelelően működni. Az "Összes elfogadása" gombra kattintva elfogadja azok használatát, beleértve az oldal viselkedésével kapcsolatos információkat is. Hozzájárulását bármikor visszavonhatja.',
    },
    cartFormLogin: {
      btnSend: 'Küld',
      button: 'Belépés',
      title: 'Belépés',
      forgottenPasswordBtn: 'Elfelejtetted a jelszót?',
      text1: 'Egy oneszeri jelszót küldünk a megadott e-mail címre.',
      text2: 'Bejelentkezés után állítson be új jelszót.',
      error: 'Rossz felhasználónév vagy jelszó.',
    },
    cartSummartSection: {
      title: 'RENDELÉS ÁTTEKINTÉSE',
    },
    appCartSummarySubjectsList: {
      title: 'TERMÉKNÉV',
      text: 'MEGNEVEZÉS',
    },
    newsletterText: {
      text1: 'Tanácsra van szüksége?',
      text2: 'Érdeklőnap minden munkanapon 8-16 óráig lehet.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(Nettó ár)',
      priceLabel: 'EGYSÉGÁR',
      amount: 'MENNYISÉG',
      totalPriceLabel: 'ÖSSZESEN',
      totalPrice: 'Összesen',
      submitButton: 'RENDBEN',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: '',
    },
    cartSummaryListItem: {
      freeTitle: '- Ingyenes',
      amountTitle: 'db',
    },
    sxCartNotes: {
      note: 'Nem szolgál a szállítás dátumának feltüntetésére',
      addNote: 'Adj hozzá megjonezést',
    },
    productDetail: {
      promoCode: {
        title: 'promóciós kóddal:',
        validTo: 'érvényes:',
      },
      productSendLink: {
        copyLinkBtn: 'Másold ki a linket',
        sendLinkByEmail: 'E-mailben a linket',
      },
      productDetailBarInformation: {
        addToFavourites: 'Add hozzá a kedvencekhez',
        compareProduct: 'Hasonlítsa össze a terméket',
        sendLink: 'Küldj linket',
        serviceList: 'Szolgáltatások listája',
        questions: 'Kérdések',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'VÁLLALATI ÜGYFELEK',
        otherDeliveryInfo: 'SZÁLLÍTÁS MÁS CÍMRE',
        otherDeliveryInfoAlert: 'Az ÁFA-alany a kézbesítéshez más címet nem vehet igénybe.',
      },
      companyNameLabel: 'Cég neve',
      firstNameLabel: 'Keresztnév',
      lastNameLabel: 'Vezetéknév',
      streetLabel: 'Utca',
      cityLabel: 'Város',
      zipLabel: 'Irányítószám',
      companyIDLabel: 'Cégjegyzékszám',
      countryLabel: 'Ország',
    },
    cartFormPersonal: {
      label: 'Levelezési cím',
      firstNameLabel: 'Keresztnév',
      lastNameLabel: 'Vezetéknév',
      streetLabel: 'Utca és házszám',
      pscLabel: 'Irányítószám',
      cityLabel: 'Város',
      countryLabel: 'Ország',
      phoneLabel: 'Telefonszám',
      emailLabel: 'Email cím',
      passwordLabel: 'Jelszó',
      controlPasswordLabel: 'Jelszó ellenőrzése',
    },
    cartFormInvoice: {
      labelDesktop: 'FIZETÉSI ADATOK / (az adatok a számlára lesznek nyomtatva)',
      labelMobile: 'Elérhetőség',
      companyLabel: 'Cég',
      password: 'Jelszó',
      confirmPassword: 'Jelszó ellenőrzése',
      email: 'Email cím',
      firstName: 'Keresztnév',
      lastName: 'Vezetéknév',
      countryLabel: 'Ország',
      phone: 'Telefonszám',
      phoneError: 'A telefonszám nem helyes',
      streetLabel: 'Utca',
      streetNumberLabel: 'Házszám',
      zipLabel: 'Irányítószám',
      icoLabel: 'Cégjegyzékszám',
      dicLabel: 'Adószám',
      country: 'Ország',
      cityLabel: 'Város',
      registration: 'Szeretnék számlát nyitni',
      registrationNote: 'Nyisson egy fiókot, és nyomon követheti rendeléseit, szállítás és fizetés.',
      button: 'Változtatások mentése',
    },
    page404: {
      title: 'Sajnos az oldal nem létezik...',
      subTitle: 'Tovább a főoldalra.',
      button: 'Vissza a főoldalra.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Rendezés:',
      byName: 'Név',
      byPrice: 'Ár',
      onlyStock: 'Csak raktáron',
      resetFilter: 'Szűrők törlése',
      displayCountOfProducts: 'Termékek:',
      search: 'Keresés',
      clear: 'Töröl',
      ok: 'Szűrő',
    },
    priceRangeSliderField: {
      title: 'Árkategória',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Szűrés vlami alapján',
    },
    productListMultipleFilterMobile: {
      title: 'SZŰRŐ',
    },
    cartCompany: {
      companyName: 'Cégszerű megnevezés',
      regNo: 'Cégjegyzékszám',
      taxNo: 'Adószám',
      taxNoPlaceholder: 'HU00000000',
      warningMessage: {
        warning: 'Fontos.',
        message: 'A cég címétől eltérő címre történő szállítás esetén a megrendelés ÁFA-köteles.',
      },
    },
    searchResultsPage: {
      match: '{resultCountFormatted} találatot találtunk a {searchText} kifejezésre.',
      nomatch: 'Nincs találat a(z) {searchText} kifejezésre.',
      matchingCategories: 'Megtalált kategóriák',
      button: 'Vissza a főoldalra.',
    },
    selectMultipleFilter: {
      totalChipsLabel: 'A kiválasztott termékek száma: {total}',
    },
    cartDelivery: {
      firstName: 'Keresztnév',
      lastName: 'Vezetéknév',
      companyName: 'Cégszerű megnevezés',
      streetLabel: 'Utca',
      streetNumberLabel: 'Házszám',
      zipLabel: 'Irányítószám',
      country: 'Ország',
      cityLabel: 'Város',
    },
    cartNotes: {
      shippingLabel: 'Megjonezés hozzáadása a szolgáltatóhoz',
      shippingNote: 'Megjonezés a fuvarozóknak',
      sellerLabel: 'Megjonezés hozzáadása az eladóhoz',
      sellerNote: 'Megjonezés az eladóknak',
      loginNote: 'Ha már van fiókja nálunk. Az űrlap a bejelentkezés után automatikusan kitöltésre kerül.',
    },
    cartCountryShipping: {
      title: 'ORSZÁG',
      label: 'Az ország kiválasztása befolyásolhatja a fizetést, a szállítást és a szállítási dátumot',
    },
    cartAddress: {
      bllingAddress: 'Számlázási cím',
      deliveryAddress: 'Levelezési cím',
      pickUpPointAddress: 'Átvételi pont címe',
    },
    cartAgreements: {
      customerVerified:
        'Nem járulok hozzá az ügyfél-elégedettség mérése, valamint szolgáltatásaink minőségének javítása érdekében megvalósuló „Ügyfelek által ellenőrzött” program részét képező kérdőív elküldéséhez.”',
    },
    loginDialog: {
      username: 'Felhasználónév',
      password: 'Jelszó',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Jelszó megerősítése',
      invalidPostalCode: 'A megadott irányítószám nem érvényes formátumú',
      usernameIsNotFree: 'Egy ilyen nevű felhasználó már regisztrálva van.',
      emailEmpty: 'Az email helyes!',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Új fiók hozzáadása',
        all: 'Minden fiók',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Szám',
            invoiceDate: 'Számla kiállítási dátuma',
            dueDate: 'Nap',
            total: 'Nettó ár',
            totalWithTax: 'Összesen',
            billingAddress: 'Számlázási cím',
            orderNumber: 'Rendelésszám',
            unpaidAmount: 'Ki nem fizetett összeg',
          },
          filter: {
            number: 'Szám',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Számlasorok',
          columns: {
            priceUnit: 'Ár',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: 'Fizetendö összeg',
      totalPriceWithTax: 'Fizetendö összeg',
      tax: 'ÁFA összesen',
      withoutTax: 'Összesen nettó',
    },
    compare: {
      addToCompare: 'A termék hozzáadásra került az összehasonlításhoz',
      removeToCompare: 'A terméket eltávolítottuk az összehasonlításból',
      price: 'Ár',
      ean: 'EAN',
    },
    favorite: {
      addToFavorite: 'A termék bekerült a kedvencek közé',
      removeFromFavorite: 'A terméket eltávolították a kedvencek közül',
    },
    registration: {
      important: 'FONTOS!',
      note: 'E-mailben küldünk Önnek egy egyszeri jelszót. Bejelentkezés után változtassa meg jelszavát.',
      gdprAgreement: 'El kell fogadnia a személyes adatok kezelését',
      submitButton: 'Regisztráció',
      fail: 'A regisztráció sikertelen volt, kérjük, próbálja újra később',
      success: 'Sikeres regisztráció',
      title: 'Bejonezés',
      dialog: 'Ha még nem rendelkezik fiókkal nálunk, regisztrálhat',
      here: 'itt',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Termékek keresése',
    },
    appBarActions: {
      about: 'A vásárlás folyamata',
      aboutHref: '/a-vasarlas-folyamata',
      compare: 'Összehasonlítás',
    },
    appBarActionUser: {
      title: 'Belépés',
    },
    footer: {
      copyright: 'COPYRIGHT © ČESKÝ VELKOOBCHOD S.R.O. - minden jog fenntartva',
    },
    appArticlesPage: {
      title: 'Cikkek',
    },
    errorPage: {
      title: 'Valami nem stimmel.',
      button: 'Főoldal',
    },
    cartSummary: {
      withoutTax: 'Nettó ár',
    },
    renewPasswordForm: {
      success: 'Új jelszót küldtünk az e-mail címére.',
      emailNotFound: 'E-mail nem található!',
      error: 'Valami nem stimmel',
    },
    cartSubjectListItemQuantityWarning: 'A maximális rendelési mennyiség {count} termék',
  },
};
export default messages;
