import React from 'react';

const mapping = {
  test: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P5BX9MB&gtm_auth=_ggEvpomA586VZt3xJ-IlQ&gtm_preview=env-8&gtm_cookies_win=x"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `,
  next: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P5BX9MB&gtm_auth=_ggEvpomA586VZt3xJ-IlQ&gtm_preview=env-8&gtm_cookies_win=x"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `,
  stable: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P5BX9MB"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `,
};

export default function useAddGtmConfigBody() {
  const environment = process.env.RAZZLE_SX_UI_ENV;
  const body = mapping[environment];

  React.useEffect(() => {
    const noscript = document.createElement('noscript');
    if (body) {
      noscript.innerHTML = body;
      document.body.prepend(noscript);
    }

    return () => {
      if (body) {
        document.body.removeChild(noscript);
      }
    };
  }, [body]);
}
