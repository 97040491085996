const messages = {
  hu: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Kosár',
        },
      },
      productDetail: {
        parameters: {
          title: 'Paraméterek',
        },
      },
      productAvailability: {
        format: {
          available: 'Raktáron',
          moreThanX: 'Több mint {min} darab van raktáron',
          exactCount: '{value} darab raktáron',
          notAvailable: 'Nem elérhető',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ további ({number}).',
      },
      relatedProducts: {
        title: 'Kapcsolódó termékek',
      },
      productAccessories: {
        title: 'kiegészítők',
      },
      productDetailImageGallery: {
        noImage: 'Nincsenek képek',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Mikor kapom meg az árut?',
      },
    },
  },
};
export default messages;
