const messages = {
  hu: {
    sprinxFormsValidation: {
      required: 'Kötelező mező!',
      string: 'Szövegnek kell lennie!',
      stringEmpty: 'Nem lehet üres',
      stringMin: 'A hossznak nagyobbnak vagy oneenlőnek kell lennie, mint {expected} karakter!',
      stringMax: 'A hossznak kisebbnek vagy oneenlőnek kell lennie, mint {expected} karakter!',
      stringLength: 'A hossznak {expected} karakternek kell lennie!',
      stringPattern: 'Nem oneezik a kért mintával!',
      stringContains: "A(z) '{expected}' szövegnek szerepelnie kell!",
      stringEnum: 'Nem egyezik egyik megengedett értékkel sem!',
      stringNumeric: 'Ez kell a szám!',
      number: 'Ez kell a szám!',
      numberMin: 'Nagyobbnak vagy egyenlőnek kell lennie, mint {expected}!',
      numberMax: 'Kisebbnek vagy oneenlőnek kell lennie, mint {expected}!',
      numberEqual: 'Meg kell egyeznie a következővel: {expected}!',
      numberNotEqual: 'Nem lehet ugyanaz, mint {expected}!',
      numberInteger: 'Egész számnak kell lennie!',
      numberPositive: 'A számnak pozitív értékűnek kell lennie!',
      numberNegative: 'A számnak negatívnak kell lennie.',
      array: 'Egy lista kell!',
      arrayEmpty: 'Nem lehet üres lista!',
      arrayMin: 'Legalább {number, plural, one {1 termék} few {{number} termék} other {{number} termék} kell lennie!',
      arrayMax:
        'Nem tartalmazhat több mint {number, plural, one {1 termék} few {{number} termék} other {{number} termék}!',
      arrayLength:
        'Pontosan tartalmaznia kell a következőt: {expected, plural, one {1 product} few {{number} products} other {{number} products}}!',
      arrayContains: 'Tartalmaznia kell a „{expected}” terméket!',
      arrayEnum: "A '{expected}' érték nem egyezik a megengedett értékekkel!",
      boolean: 'Igennek vagy nemnek kell lennie!',
      function: 'Jellemzőnek kell lennie!',
      date: 'Egy randevúnak kell lennie!',
      dateMin: 'Nagyobbnak vagy oneenlőnek kell lennie, mint {expected}!',
      dateMax: 'Kisebbnek vagy oneenlőnek kell lennie, mint {expected}!',
      forbidden: 'Tiltott!',
      email: 'Az email helyes!',
      url: 'Érvénytelen URL!',
      object: 'Object típusúnak kell lennie!',
      arrayReducedEquals: 'Az összesített érték nem oneezik a következővel: {expected}!',
      phoneNumber: "A telefonszámnak érvényes nemzetközi formátumban kell lennie! '+ XXX XXX XXX XXX'!",
      dateGt: 'A dátumnak nagyobbnak kell lennie, mint {expected}!',
      dateGte: 'A dátumnak nagyobbnak vagy egyenlőnek kell lennie, mint {expected}!',
      dateLt: 'A dátumnak rövidebbnek kell lennie, mint {expected}!',
      dateLte: 'A dátumnak kisebbnek vagy egyenlőnek kell lennie, mint {expected}!',
    },
  },
};
export default messages;
