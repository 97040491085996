const messages = {
  hu: {
    shoppingCart: {
      actions: {
        addToCart: 'KOSÁRBA',
        buy: 'megvesz',
        order: 'Rendelés',
        remove: 'Távolítsa el',
      },
      cart: {
        total: {
          label: 'Összesen',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Összesen',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Összesen',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Összesen fizetni',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'ÁFA összesen',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Állítsa vissza a kocsit',
      },
      messages: {
        confirmResetCart: 'Biztosan törli a kosár tartalmát?',
        giftClaim: '{quantity} darabot kaphat ajándékba a {productName} termékből.',
        cartManipulations: {
          allRemoved: 'Minden elem eltávolítva.',
          itemAdded: '{number, plural, one {tétel hozzáadva a kosárhoz} other {elemek hozzáadva a kosárhoz}}.',
          itemRemoved: '{number, plural, one {Elem eltávolítva a kosárból} other {Elemek eltávolítva a kosárból}}.',
          quantityChaged: 'A mennyiség megváltozott.',
        },
      },
      components: {
        appBartActionCart: 'Kosár',
        quantityField: {
          states: {
            quantityRounded: 'A mennyiséget a legközelebbi csomagméretre kerekítettük.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Az Ön kosara üres',
        },
        cartAfterAddModal: {
          continueShopping: 'folytatni a vásárlást',
          goToCart: 'Tovább a kosárhoz',
          title: 'A termékek a kosárba kerültek',
          pcs: 'db',
        },
      },
    },
  },
};
export default messages;
