const messages = {
  hu: {
    reactMuiTables: {
      selected: 'választott',
      filterhide: 'Szűrő elrejtése',
      filtershow: 'Szűrő megjelenítése',
      refresh: 'visszaállítás',
      filter: {
        actions: {
          search: 'Keresés',
          resetFilter: 'Szűrő törlése',
          addCondition: 'Adjon hozzá egy feltételt',
        },
      },
      standardOperators: {
        equals: 'egyenlő',
        contains: 'tartalmaz',
        between: {
          label: 'között',
          from: 'tól től',
          to: 'nak nek',
        },
        oneOf: 'az egyik',
      },
      labelRowsPerPage: 'Az oldalankénti sorok száma:',
    },
  },
};
export default messages;
