const messages = {
  hu: {
    app: {
      about: 'Az alkalmazásról',
      actions: {
        add: 'Hozzáadás',
        addNamed: '{name} hozzáadása',
        remove: 'Töröl',
        removeNamed: 'törölni {name}',
        removeConfirmation: 'Biztosan törli ezt?',
        more: 'Több',
        less: 'Kevésbé',
        download: 'Letöltés',
        save: 'Kikényszeríteni',
        cancel: 'Megszünteti',
        close: 'Bezárás',
        moreFormFields: 'Egy másik mező',
        export: 'Export',
        edit: 'Szerkesztés',
        createdSuccessMessage: 'Sikeresen létrehozva.',
        savedSuccessMessage: 'Sikeresen mentve.',
        deletedSuccessMessage: 'Sikeresen törölve.',
        detail: 'Részlet',
        next: 'Következő',
        back: 'Vissza',
        start: 'Kezdet',
        whatNext: 'Hol most',
        confirm: 'megerősít',
        confirmRemove: 'Biztosan törli ezt?',
        search: 'Keresés',
        userProfile: 'Felhasználói profil',
        tenantProfile: 'Az Ön CRM-profilja',
        imports: 'Importálás',
        fileImports: 'Fájlok importálása',
        settings: 'Beállítások',
        logout: 'Kijelentkezés',
        login: 'Belépés',
        filterList: 'Szűrők',
        excel: 'Excel',
        refresh: 'Visszaállítás',
        clear: 'Töröl',
        agree: 'Egyetértek',
        disagree: 'nem értek egyet',
        makeCopy: 'Másolat',
        fullscreen: 'Megtekintés teljes képernyőn',
        fullscreenClose: 'Lépjen ki a teljes képernyős nézetből',
        copy: 'másolat',
      },
      messages: {
        confirmRemove: 'Biztosan törli ezt a terméket?',
        errorOccurred: 'Hiba történt',
        createdSuccess: 'Az alkotás sikeres volt.',
        savedSuccess: 'A mentés sikeres volt.',
        deletedSuccess: 'A törlés sikeres volt.',
        uploadSuccess: 'Fájl sikeresen feltöltve.',
        uploadError: 'Hiba a fájl feltöltésekor.',
        uploadInProgress: 'Betöltés...',
        uploadedFiles:
          '{number, plural, one {1 fájl feltöltve} few {{number} fájl feltöltve} other {{number} fájl feltöltve}}',
      },
      error: 'Hiba!',
      validations: {
        isRequired: 'A(z) {field} mező kitöltése kötelező.',
        minLengthIsRequired:
          'Legalább {number, plural, one {1 item} few {{number} item} other {{number} termék} szükséges.',
        minValue: 'A minimálisan megengedett érték {value}.',
        maxValue: 'A megengedett maximális érték {value}.',
        passwordMatchError: 'A jelszavak nem egyeznek',
        invalidEmail: 'Az e-mail cím érvénytelen.',
        invalidPhoneNumber: "A telefonszámnak érvényes nemzetközi formátumban kell lennie! '+ XXX XXX XXX XXX'!",
        notValidNumber: 'Érvénytelen szám',
        invalidUrl: 'Érvénytelen URL.',
        positive: 'A számnak pozitív értékűnek kell lennie.',
        negative: 'A számnak negatívnak kell lennie.',
      },
      login: {
        title: 'Jelentkezzen be a Sprinx CRM-be',
        username: 'Felhasználónév',
        password: 'Jelszó',
        loginButton: 'Belépés',
        error: 'A bejelentkezés nem sikerült. Felhasználónév vagy jelszó nem található',
        pleaseRelogin: 'Úgy tűnik, nincs bejelentkezve. Kérjük, próbáljon meg újra bejelentkezni.',
      },
      days: '{count, plural, one {1 nap} few {{formattedCount} nap} other {{formattedCount} nap}}',
      overdue: 'késleltetés {count, plural, one {1 nap} few {{formattedCount} nap} other {{formattedCount} nap}}',
      newItem: 'Új',
      anonymizing: {
        confirm: {
          title: 'Erősítse meg az anonimizálást',
          content: 'Biztosan anonimizálni szeretné a személyes adatokat?',
        },
        button: 'A személyes adatok anonimizálása',
      },
      appMenu: {
        discover: 'Felfedez',
      },
      missingType: 'Először adja meg a következőt: {typeName} az alkalmazás adminisztrációjában.',
      missingTypeForEntityType:
        'Először adja meg a {typeName} értéket a {forEntityTypeName} számára az alkalmazás adminisztrációjában.',
      emptyScoringBlog:
        'Azok a modulok, amelyek tartalma vagy beállításai jelennek meg ezen az oldalon, nincsenek aktiválva.',
    },
    components: {
      buttons: {
        showOnMap: 'mutasd meg a térképen',
      },
      filters: {
        filterButtonSubmit: 'Keresés',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Adjon hozzá one feltételt',
        resetFilter: 'Szűrő törlése',
        operators: {
          contains: {
            0: 'tartalmaz',
            _: 'tartalmaz',
          },
          containsexact: {
            0: 'pontosan tartalmazza',
            _: 'pontosan tartalmazza',
          },
          notcontains: {
            0: 'nem tartalmaz',
            _: 'nem tartalmaz',
          },
          betweennumber: {
            0: 'tól től',
            1: 'nak nek',
            _: 'belülről',
          },
          betweendate: {
            0: 'tól től',
            1: 'nak nek',
            _: 'belülről',
          },
          equal: {
            0: 'ugyanaz',
            _: 'ugyanaz',
          },
        },
      },
      paginationList: {
        itemsTotal: 'Termékek teljes száma: {number}',
        nextPage: 'Terméklista: {number}',
        emptyMessage: 'Nem található termék.',
      },
      selects: {
        emptyOption: 'Üres',
        emptyMessage: 'Nem található termék.',
      },
      tables: {
        labelRowsPerPage: 'Egy sor egy oldalon',
        sortBy: 'Rendezés: {field}',
        recordsChanged: 'Néhány bejegyzés hozzáadásra vagy módosításra került.',
      },
      textField: {
        errorMsgWrapper: '',
      },
      dateField: {
        invalidDateFormat: 'Hibás dátumformátum.',
        invalidDate: 'Rossz dátum.',
        maxDateMessage: 'A dátum nem lehet hosszabb, mint a maximális dátum',
        minDateMessage: 'A dátum nem lehet rövidebb a minimális dátumnál',
        today: 'Ma',
      },
      detailNavigation: {
        scoringblog: 'Pontozó blog',
        card: 'Kártya',
        relations: 'Lenni',
      },
      manipulationInfo: {
        created: 'Létrehozva',
        updated: 'Megváltozott',
        removed: 'Törölve',
      },
    },
  },
};
export default messages;
