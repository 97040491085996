const messages = {
  hu: {
    orders: {
      actions: {
        addNewOrder: 'Új rend',
        all: 'Minden rendelés',
      },
      listPage: {
        title: 'Megrendelések',
        table: {
          filter: {
            number: 'Szám',
          },
          columns: {
            contact: 'Kapcsolatba lépni',
            dateOfOrder: 'Megrendelés dátuma',
            number: 'Szám',
            offer: 'Ajánlat',
            opportunity: 'Lehetőség',
            owner: 'Tulajdonos',
            state: 'Ország',
            total: 'Összesen',
            totalUserCurrency: 'Összesen {currency}',
          },
        },
      },
      order: {
        newEntity: 'Új rend',
        contact: {
          label: 'Kapcsolatba lépni',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Teljes jutalék',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Valuta',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Megrendelés dátuma',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Szám',
          helperText: 'Hagyja üresen az automatikus számkiegészítéshez.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Ajánlat',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Lehetőség',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Tulajdonos',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Árrés',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Név',
          discount: {
            label: 'Kedvezmény',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'jutalék',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Csomagolás',
          },
          listPrice: {
            label: 'listaár',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Ár',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Termék',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Összeg',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Árrés',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Összesen',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Pc',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Összesen',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {nincs termék} one {1 termék} few {{number} termék} other {{number} termék}',
      },
      myOrdersScoringBlog: {
        title: 'rendeléseim',
      },
      ordersList: 'Megrendelések',
    },
  },
};
export default messages;
